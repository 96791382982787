<template>
  <div id="quotes">
    <div class="border-bottom pb-4">
      <h4>Quotes List</h4>
      <ul class="list-unstyled" v-if="quotes.length">
        <li v-for="quote in quotes"
          v-bind:key="quote.id"
          class="container-fluid p-3 rounded shadow mb-3">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">{{quote.plan.name}}</h5>
                <span class="badge badge-success">{{quote.status}}</span>
              </div>
            </div>
            <div class="col-12">
              <h4 class="text-primary m-0">${{quote.plan.price}} {{quote.plan.currency}}</h4>
            </div>
            <div class="col-12 mb-2">
              <span class="text-muted">
                Created at {{quote.createdAt.toDate().toLocaleDateString("en-US", dateConfig)}}
              </span>
            </div>
            <div class="col-12">
              <a href="javascript:;" class="btn btn-primary">Purchase Now</a>
            </div>
          </div>
        </li>
      </ul>
      <template v-else>
        <h5>Oops!</h5>
        <span class="text-muted">No quotes were found.</span>
      </template>
    </div>
  </div>
</template>

<script>
import { auth, firestore, } from "@/services/firebase.service";
  export default {
    name: "Quotes",
    data() {
      return {
        quotes: [],
        dateConfig: {
          day  : "2-digit",
          month: "long",
          year : "numeric",
        }
      };
    },
    methods: {
      getQuotes() {
        const { uid, } = auth.currentUser;
        const userReference = firestore.doc("/users/" + uid);

        firestore.collection("Quotes")
          .where("userRef", "==", userReference)
          .get()
          .then(snapshot => {
            snapshot.forEach(async doc => {
              const quote = {
                createdAt: doc.get("createdAt"),
                plan     : await this.getPlanByReference(doc.get("planRef")),
                status   : doc.get("status"),
              };
              this.quotes.push({ id: doc.id, ...quote, });
            });
          })
          .catch(e => {
            console.error(e);
            this.$swal(
              "Oops!",
              "An error occurred while fetching the quotes from database",
              "error",
            );
          })
      },
      /**
       * @param reference Firebase plan's reference
       * @returns A promise fulfilled with the plan
       */
      getPlanByReference(reference) {
        return new Promise((resolve, reject) => {
          reference.get()
            .then(snapshot => resolve({
              name    : snapshot.get("name"),
              price   : snapshot.get("price"),
              currency: snapshot.get("currency"),
            }))
            .catch(e => reject(e));
        });
      },
    },
    created() {
      this.getQuotes();
    },
  };
</script>